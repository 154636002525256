body {
  overflow-x: hidden;
}

@mixin text-under-title {
  margin-top: 30px;
  color: #777777;
  line-height: 40px;
}

body {
  overflow-x: hidden;
}

.ansy-h1 {
  font-weight: normal;
}

.ansy-h2 {
  font-size: 42px;
  color: #555555;
  font-weight: 500;
}

.ansy-h2_white {
  color: #ffffff;
}

.main-page {
  position: relative;
  overflow: hidden;
}

td.color-green {
  color: #69B8AA;
}

td.fz-36 {
  font-size: 36px;
}

td.fz-24 {
  font-size: 24px;
}

td.fz-18 {
  font-size: 18px;
}

td.fz-16 {
  font-size: 16px;
}

td.fz-14 {
  font-size: 14px;
}

.fw500 {
  font-weight: 500;
}

.title-adaptive-design {
  padding-left: 30px;
  max-width: 506px;
  position: absolute;
  top: -209px;

  p {
    font-size: 18px;
    color: #777777;
    line-height: 32px;
    padding-top: 20px;
  }
}

.delay-1 {
  animation-delay: .25s;
}
.delay-2 {
  animation-delay: .5s;
}
.delay-3 {
  animation-delay: .75s;
}
.delay-4 {
  animation-delay: 1s;
}

.example-img {
  width: 100%;
  position: relative !important;
  opacity: 0;
}

@media (max-width: 1199.98px) {

  .title-adaptive-design {
    top: 50px;
    width: 100%;
    max-width: 100%;
  }

  .ansy-h1 {
    font-size: 52px;
  }

  .example-img {
    margin-bottom: 60px;
  }
}

@media (max-width: 767.98px) {

  .title-adaptive-design {
    padding-left: 0;
    max-width: 93%;
    margin: auto;
    left: 0;
    right: 0;
  }

  .adaptive-text {
    font-size: 16px !important;
    line-height: 24px !important;
  }

  .ansy-h2 {
    font-size: 24px;
  }

  .sample-pages__text span {
    font-size: 22px;
  }

  .color-section__title {
    font-size: 20px;
  }

  .title-adaptive-design p {
    font-size: 16px;
    line-height: 24px;
  }
}

@media (max-width: 575.98px) {
  h1 {
    font-size: 36px !important;
  }
}
