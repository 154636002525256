.color-section {
  display: flex;
  flex-direction: column;
  margin-top: 50px;
}

.color-section_block {
  display: flex;
  align-items: center;
  margin-bottom: 21px;
  width: 100%;
}

.color-section__title {
  transform: rotate(270deg);
  font-size: 22px;
  color: #555555;
  position: absolute;
  left: -70px;
  width: 90px;
}

.color-section__list {
  position: relative;
  width: 33%;
  margin-right: 20px;


  p {
    position: absolute;
    font-size: 12px;
    color: #777777;
    right: 40px;
    top: 17px;
  }
}

.color-section__item {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  border-radius: 4px;
  height: 175px;
}

.color-section__item-color {
  min-width: 70px;
  height: 70px;
  display: block;
  border-radius: 50%;
}

.color-section__item-color-red {
  background: #C72839;
  box-shadow: inset 0 0 0 1px #ffffff;
  border: 1px solid #C72839;
}

.color-section__item-color-turquoise {
  background: #69B8AA;
  box-shadow: inset 0 0 0 1px #ffffff;
  border: 1px solid #69B8AA;
}

.color-section__item-color-white {
  background: #ffffff;
  border: 1px solid #CFCFCF;
}

.color-section__item-color-333 {
  background: #333;
  box-shadow: inset 0 0 0 1px #ffffff;
  border: 1px solid #333;
}

.color-section__item-color-555 {
  background: #555;
  box-shadow: inset 0 0 0 1px #ffffff;
  border: 1px solid #555;
}

.color-section__item-color-777 {
  background: #777;
  box-shadow: inset 0 0 0 1px #ffffff;
  border: 1px solid #777;
}

@media (max-width: 610px) {
  .color-section_block {
    flex-direction: column;
    margin-top: 50px;
  }

  .color-section__title {
    position: static;
    transform: none;
  }

  .color-section__list {
    margin-left: 0;
    margin-right: 0;
    margin-top: 20px;
    max-width: 175px;
    width: 90%;
  }

  .color-section {
    margin-top: 0;
    display: flex;
    flex-direction: row;
  }
}