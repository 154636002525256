.design-system {
  background: #EBECED;
}

.design-system-section {
  padding-top: 160px;
  padding-bottom: 90px;
}

.design-system-section__text {

  span.display-1 {
    line-height: 126px;
    font-weight: bold;
    font-size: 95px;
    text-transform: uppercase;
  }

  .design-system__caption {
    font-size: 18px;
    line-height: 32px;
    color: #777777;
    max-width: 475px;
    margin-top: 46px;
  }
}

.design-system-section__fonts {
  margin-top: 95px;

  span {
    font-size: 144px;
    color: #333333;
  }
}

.design-system-section__fonts-title {
  font-size: 24px;
  font-weight: bold;
  color: #333333;
}

.design-system-section__fonts-name {
  font-size: 25px;
  color: #8B8B8B;
  position: relative;
  bottom: 30px;
}

.design-system-section__block {
  margin-top: 87px;
}

.design-system-section__colors {
  margin-top: 95px;
}

.design-system-section__colors-title {
  font-weight: bold;
  margin-left: 50px;
}

@media (max-width: 991.98px) {
  .design-system-section {
    padding-top: 80px;
    padding-bottom: 50px;
  }

  .design-system-section__text span.display-1 {
    font-size: 50px;
    line-height: normal;
  }

  .design-system-section__text .design-system__caption {
    max-width: 100%;
  }

  .design-system-section__block {
    margin-top: 60px;
  }

  .design-system-section__text h1 {
    word-spacing: normal;
  }
}

@media (max-width: 767.98px) {
  .design-system-section {
    padding-top: 50px;
    padding-bottom: 40px;
  }

  .design-system-section__text h1 {
    word-spacing: normal;
  }

  .design-system-section__colors table {
    position: relative;
    left: -30px;
  }

  .design-system-section__colors-title {
    margin-left: 0;
  }

  .design-system-section__fonts,
  .design-system-section__colors {
    margin-top: 30px;
  }

  .design-system-section__text span.display-1 {
    font-size: 32px !important;
  }
}