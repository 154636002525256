.design-system-fonts {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
}

.design-system-fonts__sample {
  display: flex;
  align-items: center;
  margin-bottom: 21px;
}

.design-system-fonts__sample-block {
  width: 33%;
  margin-right: 20px;
  border: 1px solid #FFFFFF;
  height: 175px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  span {
    font-size: 100px;
    line-height: 75px;
    margin-top: 30px;
    color: #555555;
    font-family: 'Proxima Nova', sans-serif;
  }

  p {
    font-size: 14px;
    line-height: 6px;
    color: #555555;
    font-family: 'Proxima Nova', sans-serif;
  }
}

.design-system-fonts__example {
  display: flex;
  flex-direction: column;
}

.design-system-fonts__example-container {
  display: flex;
  align-items: center;

  p {
    margin-bottom: 0;
    font-size: 20px;
    color: #555555;
    width: 70px;
    margin-right: 60px;
  }

  .design-system-fonts__example-size {
    width: 180px;
    display: flex;
    align-items: center;

    span {
      margin-right: 30px;
    }
  }

  .design-system-fonts__example-font {
    width: 185px;
    display: flex;
    align-items: center;
    margin-left: auto;
    margin-right: auto;

    span {
      margin-right: 25px;
    }
  }
}

td.design-system-fonts__title {
  width: 100px;
}

.design-system-fonts__width {
  width: 100px;
}

.design-system-fonts__table {
  td {
    vertical-align: middle;
    height: 60px;
    font-size: 20px;
    color: #555555;
    font-family: 'Proxima Nova', sans-serif;
  }
}

@media (max-width: 1199.98px) {
  .design-system-fonts__sample-block p {
    text-align: center;
    line-height: 10px;
  }
}

@media (max-width: 520px) {

  .design-system-fonts__sample-block {
    margin-right: 0;
    margin-bottom: 20px;
    max-width: 175px;
    width: 100%;

    span {
      font-size: 70px;
    }

    p {
      line-height: 15px;
    }
  }

  .design-system-fonts__table {
    tbody {
      display: flex;
      justify-content: space-around;
    }

    tr {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

     td {
       text-align: center;
       height: 40px;
     }
  }
}