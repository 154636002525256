.grid-section {
  padding-top: 35px;
}

.grid-block-twelve__icon {
  background: url("/assets/img/1.svg") no-repeat center;
  background-size: 100% auto;
  width: 61px;
  height: 45px;
}

.grid-block-sex__icon {
  background: url("/assets/img/2.svg") no-repeat center;
  background-size: 100% auto;
  width: 39px;
  height: 48px;
}

.grid-block-two__icon {
  background: url("/assets/img/3.svg") no-repeat center;
  background-size: 100% auto;
  width: 28px;
  height: 42px;
}

.grid-block__icon {
  display: flex;
  justify-content: center;
  height: 50px;
  i {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 15px;
  }
}

.grid-block {
  position: relative;
  span {
    display: block;
    text-align: center;
    font-family: 'Proxima Nova', sans-serif;
    font-size: 22px;
    color: #555555;
  }

  img {
    width: 100%;
  }
}

.grid-block-twelve {
  img {
    position: relative;
  }

  span {
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
  }
}

.grid-block-two {
  img {
    position: relative;
    margin-top: 11px;
  }
}

.grid-block-six {
  img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 11px;
  }
}

.grid-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 40px;
  margin-bottom: 70px;
}

.grid-container__column {
  display: flex;
  justify-content: space-between;
}

.grid-container_width {
  width: 50%;
}

.grid-section__text-block {
  max-width: 675px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.grid-section__text {
  font-size: 18px;
  color: #777777;
}

@media (max-width: 1199.98px) {
  .grid-container {
    flex-direction: column;
    max-width: 695px;
    margin-left: auto;
    margin-right: auto;
  }

  .grid-container_width {
    width: 100%;
  }

  .grid-container__column {
    margin-top: 40px;
  }
}

@media (max-width: 991.98px) {
  .grid-section__text-block {
    max-width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
}

@media (max-width: 767.98px) {
  .grid-section__text {
    font-size: 16px;
  }

  .grid-block span {
    font-size: 18px;
  }

  .grid-block-two {
    margin-left: 20px;
  }
}
