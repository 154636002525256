.adaptability-section {
  margin-top: 130px;

  p {
    @include text-under-title;
  }
}

.adaptability-section__phone-img {
  background: url("/assets/img/phone.png") no-repeat center;
  background-size: 100% auto;
  width: 100%;
  height: 557px;
  display: block;
  margin-bottom: 40px;
  position: relative;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  opacity: 0;
}

@media (max-width: 1199.98px) {
  .adaptability-section__phone-img {
    margin-top: 260px;
    height: 380px;
  }
}

@media (max-width: 991.98px) {
  .adaptability-section__phone-img {
    height: 400px;
    margin-top: 170px;
    margin-bottom: 0;
  }
}

@media (max-width: 767.98px) {
  .adaptability-section {
    margin-top: 60px;
  }

  .adaptability-section__phone-img {
    height: 300px;
  }
}
