.interface-features {
  background: url(/assets/img/header-bg.png) repeat center;
  background-size: 8px 8px;
  width: 100%;
  height: auto;
  padding-top: 45px;
  padding-bottom: 140px;

  .ansy-h2_white {
    margin-bottom: 95px;
  }
}

.interface-features__text {
  color: #ffffff;
  margin-bottom: 50px;

  span {
    font-size: 32px;
    font-weight: bold;
    line-height: 43px;
    margin-bottom: 17px;
    display: block;
  }

  p {
    font-size: 18px;
    color: #BDBDBD;
    line-height: 32px;
    max-width: 500px;
  }
}

.interface-features__inner_materials {
  margin-bottom: 90px;

  ul {
    position: relative;
  }

  li:nth-child(1) {
    background: url("/assets/img/lint-free.svg") no-repeat center;
    background-size: 100% auto;
    width: 34px;
    height: 34px;
  }

  li:nth-child(2) {
    background: url("/assets/img/woolen_and_silk.svg") no-repeat center;
    background-size: 100% auto;
    width: 39px;
    height: 42px;
  }

  li:nth-child(3) {
    background: url("/assets/img/silk.svg") no-repeat center;
    background-size: 100% auto;
    width: 34px;
    height: 34px;
  }

  li:nth-child(4) {
    background: url("/assets/img/woolen.svg") no-repeat center;
    background-size: 100% auto;
    width: 40px;
    height: 39px;
  }
}

.interface-features__inner_country {
  margin-bottom: 90px;

  ul {
    margin-bottom: 50px;
    position: relative;
  }

  ul:nth-child(1) {
    li:nth-child(1) {
      background: url("/assets/img/Persia.svg") no-repeat center;
      background-size: 100% auto;
      width: 41px;
      height: 31px;
    }

    li:nth-child(2) {
      background: url("/assets/img/Turkey.svg") no-repeat center;
      background-size: 100% auto;
      width: 40px;
      height: 33px;
    }

    li:nth-child(3) {
      background: url("/assets/img/Pakistan.svg") no-repeat center;
      background-size: 100% auto;
      width: 31px;
      height: 39px;
    }

    li:nth-child(4) {
      background: url("/assets/img/Iran.svg") no-repeat center;
      background-size: 100% auto;
      width: 37px;
      height: 38px;
    }
  }

  ul:nth-child(2) {
    li:nth-child(1) {
      background: url("/assets/img/Azerbaijan.svg") no-repeat center;
      background-size: 100% auto;
      width: 38px;
      height: 38px;
    }

    li:nth-child(2) {
      background: url("/assets/img/Tibet.svg") no-repeat center;
      background-size: 100% auto;
      width: 50px;
      height: 32px;
    }

    li:nth-child(3) {
      background: url("/assets/img/India.svg") no-repeat center;
      background-size: 100% auto;
      width: 43px;
      height: 32px;
    }

    li:nth-child(4) {
      background: url("/assets/img/Caucasus.svg") no-repeat center;
      background-size: 100% auto;
      width: 45px;
      height: 35px;
    }
  }

  ul:nth-child(3) {
    margin-bottom: 0;
    li:nth-child(1) {
      background: url("/assets/img/Nepal.svg") no-repeat center;
      background-size: 100% auto;
      width: 52px;
      height: 32px;
    }

    li:nth-child(2) {
      background: url("/assets/img/Dagestan.svg") no-repeat center;
      background-size: 100% auto;
      width: 41px;
      height: 41px;
    }

    li:nth-child(3) {
      background: url("/assets/img/China.svg") no-repeat center;
      background-size: 100% auto;
      width: 41px;
      height: 40px;
    }

    li:nth-child(4) {
      background: url("/assets/img/Afghanistan.svg") no-repeat center;
      background-size: 100% auto;
      width: 40px;
      height: 41px;
    }
  }
}

.interface-features__inner_advantages {
  ul {
    li:nth-child(1) {
      background: url("/assets/img/individuality.svg") no-repeat center;
      background-size: 100% auto;
      width: 47px;
      height: 43px;
    }

    li:nth-child(2) {
      background: url("/assets/img/practicality-and-operation.svg") no-repeat center;
      background-size: 100% auto;
      width: 48px;
      height: 20px;
    }

    li:nth-child(3) {
      background: url("/assets/img/ecological-compatibility.svg") no-repeat center;
      background-size: 100% auto;
      width: 47px;
      height: 32px;
    }

    li:nth-child(4) {
      background: url("/assets/img/exclusive-design.svg") no-repeat center;
      background-size: 100% auto;
      width: 47px;
      height: 36px;
    }
  }
}

.interface-features__inner {
  display: flex;
  align-items: flex-start;

  ul {
    display: flex;
    align-items: center;
    padding-left: 0;
    max-width: 340px;
    width: 100%;
    justify-content: space-between;
  }

  li {
    list-style-type: none;
  }

  span {
    font-family: 'Proxima Nova', sans-serif;
    font-size: 20px;
    color: #BDBDBD;
    max-width: 150px;
    width: 100%;
    margin-top: 7px;
  }
}

.interface-features__list {
  width: 100%;
}

.interface-features__list-country {
  position: relative;

  &:after {
    content: '';
    background: url("/assets/img/line-interface.svg") no-repeat center;
    background-size: 100% auto;
    width: 36px;
    height: 180px;
    position: absolute;
    right: 32px;
    top: 20px;
  }
}

.interface-features__list-materials,
.interface-features__list-advantages {
  position: relative;
  &:after {
    content: '';
    position: absolute;
    right: 32px;
    top: 20px;
    width: 36px;
    height: 1px;
    background: #D8D8D8;
  }
}

.interface-features__content {
  position: relative;
}

.interface-features__content-animate {
  position: absolute;
  right: -70px;
  bottom: -125px;
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .interface-features__text {
    margin-bottom: 85px;
  }
}

@media (max-width: 1199.98px) {
  .interface-features__inner ul {
    max-width: 216px;
  }
}

@media (max-width: 991.98px) {
  .interface-features__inner ul {
    max-width: 400px;
  }

  .interface-features__text p {
    max-width: 100%;
  }

  .interface-features {
    padding-bottom: 60px;
  }

  .interface-features .ansy-h2_white {
    margin-bottom: 50px;
  }
}

@media (max-width: 767.98px) {
  .interface-features__text span {
    font-size: 20px;
    line-height: 24px;
  }

  .interface-features__text p {
    font-size: 16px;
    line-height: 24px;
  }

  .interface-features__content img {
    width: 100%;
    position: static;
  }

  .interface-features__inner {
    flex-direction: column;
    align-items: center;
  }

  .interface-features__list-materials:after,
  .interface-features__list-advantages:after,
  .interface-features__list-country:after{
    display: none;
  }

  .interface-features__inner ul {
    max-width: 100%;
  }

  .interface-features__inner span {
    text-align: center;
    order: 1;
    margin-bottom: 20px;
  }

  .interface-features__list {
    order: 2;
  }
}
