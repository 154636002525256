.seo-features {
  padding-top: 53px;
  padding-bottom: 60px;

  .ansy-h2 {
    margin-bottom: 70px;
  }
}

.seo-features__img {
  img {
    width: 100%;
  }
}

.seo-features__cunning {
  padding-top: 70px;
  padding-left: 30px;

  span {
    font-size: 32px;
    font-weight: bold;
    line-height: 43px;
    color: #555555;
  }

  p {
    margin-bottom: 0;
    font-size: 18px;
    line-height: 32px;
    color: #777777;
    margin-top: 15px;
    max-width: 430px;
  }
}

.seo-features__microanimations {
  padding-top: 110px;
  padding-left: 40px;

  span {
    font-size: 32px;
    font-weight: bold;
    line-height: 43px;
    color: #555555;
  }

  p {
    margin-bottom: 0;
    font-size: 18px;
    line-height: 32px;
    color: #777777;
    margin-top: 15px;
    max-width: 430px;
  }
}

@media (max-width: 1199.98px) {
  .seo-features__cunning {
    padding-top: 30px;
  }
}

@media (max-width: 991.98px) {
  .seo-features__microanimations {
    padding-left: 30px;
    padding-top: 60px;
  }

  .seo-features__cunning p,
  .seo-features__microanimations p {
    max-width: 100%;
  }

  .seo-features__microanimations p {
    margin-bottom: 30px;
  }

  .seo-features__cunning {
    padding-top: 10px;
  }

  .seo-features .ansy-h2 {
    margin-bottom: 50px;
  }
}

@media (max-width: 767.98px) {
  .seo-features__cunning span,
  .seo-features__microanimations span {
    font-size: 22px;
  }

  .seo-features__cunning p,
  .seo-features__microanimations p {
    font-size: 16px;
    line-height: 24px;
  }

  .seo-features__cunning,
  .seo-features__microanimations {
    padding-left: 0;
  }
}