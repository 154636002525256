.site-templates-section {
  margin-top: 130px;

  .row {
    justify-content: flex-end;
    position: relative;
  }
}

.site-templates-section__text {
  font-size: 18px;
  line-height: 32px;
  color: #777777;
  margin-top: 20px;
}

.site-templates-section__img {
  position: relative;
  width: 100%;
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
}

.site-templates-section__img img {
  position: absolute;
  max-width: 1440px;
}

.site-templates-section__first-pattern {
  width: 100%;
  top: 900px;
  left: 1800px;
  z-index: -1;
}

.site-templates-section__second-pattern {
  top: -760px;
  right: 1800px;
  width: 100%;
  z-index: -1;
}

.site-templates-section h1 {
  white-space: nowrap;
}

@media (max-width: 1199.98px) {
  .site-templates-section {
    margin-top: 60px;
  }

  .site-templates-section h1 {
    white-space: normal;
  }
}

@media (max-width: 991.98px) {
  .site-templates-section__img {
    display: none;
  }
}

@media (max-width: 767.98px) {
  .site-templates-section {
    margin-top: 60px;
  }

  .site-templates-section__second-pattern {
    top: -660px;
    right: 1800px;
    width: 100%;
    z-index: -1;
  }
}