
@font-face {
  font-family: 'Proxima Nova';
  src: url('/assets/fonts/NOVA/Proxima-Nova-Thin.otf');
  font-weight: 100;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('/assets/fonts/NOVA/ProximaNova-Light.ttf');
  font-weight: 300;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('/assets/fonts/NOVA/ProximaNova-Regular.ttf');
  font-weight: 400;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('/assets/fonts/NOVA/ProximaNova-Bold.ttf');
  font-weight: 700;
  font-style: normal;
  font-display: fallback;
}
