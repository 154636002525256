.header-page {
  background: url("/assets/img/header-bg.png") repeat center;
  background-size: 8px 8px;
  width: 100%;
  height: auto;
}

.header-logo {
  margin-top: 24px;
  display: flex;

  img {
    background-size: 100% auto;
    display: block;
  }
}

.header-logo__webdelo {
  background: url("/assets/img/logo-webdelo.svg") no-repeat center;
  width: 194px;
  height: 44px;
}

.header-logo__ansy {
  background: url("/assets/img/logo-ansy.svg") no-repeat center;
  width: 141px;
  height: 46px;
  margin-left: 28px;
}

.header-basic_information {
  margin-top: 180px;
  padding-bottom: 135px;

  h1 {
    color: #fff;
  }

  p {
    color: #B4B4B4;
    margin-top: 40px;
    font-size: 18px;
    font-family: 'Roboto', sans-serif;
  }
}

.header-page button {
  width: 250px;
  height: 60px;
  margin-top: 120px;
}

.header-page__container {
  position: relative;
}

@media (max-width: 991.98px) {
  .header-basic_information {
    z-index: 999;
    margin-top: 40px;
    margin-bottom: 13%;
    padding-top: 40px;
    padding-bottom: 40px;
    max-width: 400px;
  }
}

@media (max-width: 767.98px) {
  .header-basic_information p {
    font-size: 16px;
  }

  .header-page .header-basic_information-btn {
    display: none;
  }

  .header-page {
    overflow: hidden;
    height: auto;
  }

  .header-basic_information {
    margin-bottom: 50%;
    max-width: 100%;
  }

}

@media (max-width: 575.98px) {
  .header-logo__ansy,
  .header-logo__webdelo {
    background-size: 100% auto;
  }
}
